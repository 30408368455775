// import * as React from "react";
import React, { useState } from "react";
import styles from "./footer.module.scss";
import { ReactComponent as ZunuLogo } from "../../../assets/svg/zunu-logo-colored.svg";
import { ReactComponent as ZunuLogoColored } from "../../../assets/svg/zunu-logo-white.svg";
import { ReactComponent as Design } from "../../../assets/svg/footerDesign.svg";
import PrivacyMattersDark from "../../../assets/svg/privacyMattersDark.svg";
import PrivacyMattersLight from "../../../assets/svg/privacyMattersLight.svg";
import Button from "../Button/Button.component";
import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import useFooterHook from "./footer.hook";
import { ENVIRONMENT } from "../../../environment";
import { DEVICE, THEME } from "../../constants/constants";
import { FlagsDropdown } from "../FlagsDropdown/flagsDropdown";
import { ReactComponent as LinkedIn } from "../../../assets/svg/LinkedIn.svg";
import { ReactComponent as Facebook } from "../../../assets/svg/Facebook.svg";
import { ReactComponent as XIcon } from "../../../assets/svg/twitter.svg";
import { ReactComponent as Instagram } from "../../../assets/svg/instagram.svg";
import { ReactComponent as Youtube } from "../../../assets/svg/youTube.svg";
import ZirohLogo from "../../../assets/png/zirohLogo.png"
import { ROUTES } from "../../constants/routes";
import { ReactComponent as Keep_private_logo } from '../../../assets/svg/keep_private_icon.svg'
import { useDeviceStore } from "../../../StateManager/DeviceStore";

export function Footer() {
  const { theme, setTheme } = useFooterHook();
  const { device, setDevice } = useDeviceStore();

  const location = useLocation();

  return (
    <div className={styles.footer}>
      <div className={styles.footerHeader}>
        <div className={styles.headerLogoContainer}>
          <img src={ZirohLogo} alt="Ziroh Labs" className={styles.zirohLogo} />
          {theme == THEME.LIGHT ? (

            <ZunuLogoColored className={styles.zunuLogo} />
          ) : (
            <ZunuLogo className={styles.zunuLogo} />
          )}
        </div>
        {device == DEVICE.MOBILE &&
          <div className={styles.linksContainer}>

            <Link reloadDocument to={ROUTES.POLICY} target="_blank">
              Privacy Policy
            </Link>


            <Link reloadDocument to={ROUTES.TERMS} target="_blank">
              Terms of Service
            </Link>

            <Link reloadDocument to={ROUTES.ACADEMIC_ALLIANCE} target='_blank'>Academic Alliance</Link>

            <Link reloadDocument to={ROUTES.BLOGS} target='_blank'>Blogs</Link>

            <Link reloadDocument to={ROUTES.CAREERS}>Careers</Link>

            <Link reloadDocument to={ROUTES.SUPPORT}>Support</Link>

            <Link reloadDocument to={ROUTES.CONTACT_US}>Contact Us</Link>
          </div>
        }
      </div>
      {device !== DEVICE.MOBILE &&
        <div className={styles.linksContainer}>

          <Link reloadDocument to={ROUTES.POLICY} target="_blank">
            Privacy Policy
          </Link>


          <Link reloadDocument to={ROUTES.TERMS} target="_blank">
            Terms of Service
          </Link>

          <Link reloadDocument to={ROUTES.ACADEMIC_ALLIANCE} target='_blank'>Academic Alliance</Link>
          
          <Link reloadDocument to={ROUTES.BLOGS} target='_blank'>Blogs</Link>

          <Link reloadDocument to={ROUTES.CAREERS}>Careers</Link>

          <Link reloadDocument to={ROUTES.SUPPORT}>Support</Link>

          <Link reloadDocument to={ROUTES.CONTACT_US}>Contact Us</Link>
        </div>

      }
      <div className={styles.keepPrivateLogoContainer}>
        <div className={styles.privacyMediaContainer}>
          <div className={styles.privacyLogoContainer}>
            {/* {theme == "light" ? (
          <img src={PrivacyMattersLight} alt="" loading="lazy" />
         ) : (
          <img src={PrivacyMattersDark} alt="" loading="lazy" />
         )} */}
            {/* Keep your <span>private</span> life <span>private.</span> */}
            <Keep_private_logo className={styles.keepPrivateLogo} />
          </div>

          <div className={styles.mediaIconContainer}>
            <Link reloadDocument to={ROUTES.SOCIAL_MEDIA.LINKEDIN} target="_blank" aria-label="LinkedIn"><LinkedIn className={styles.mediaIcons} /></Link>
            <Link reloadDocument to={ROUTES.SOCIAL_MEDIA.FACEBOOK} target="_blank" aria-label="Facebook"><Facebook className={styles.mediaIcons} /></Link>
            <Link reloadDocument to={ROUTES.SOCIAL_MEDIA.X} target="_blank" aria-label="X"><XIcon className={styles.mediaIcons} /></Link>
            <Link reloadDocument to={ROUTES.SOCIAL_MEDIA.INSTAGRAM} target="_blank" aria-label="Instagram"><Instagram className={styles.mediaIcons} /></Link>
            <Link reloadDocument to={ROUTES.SOCIAL_MEDIA.YOUTUBE} target="_blank" aria-label="Youtube"> <Youtube className={styles.mediaIcons} /></Link>
          </div>
          {/* <p className={styles.copyright}>© 2024 Ziroh Labs. All rights reserved.</p> */}
        </div>


        <p className={styles.copyright}>© 2025 Ziroh Labs Inc. All rights reserved.</p>
      </div>
    </div>
  );
}
